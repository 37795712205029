<template>
  <v-dialog
    max-width="290px"
    min-height="145"
    :value="true"
    @click:outside="close"
  >
    <v-sheet class="white">
      <v-btn
        :min-width="createBookingWidth"
        min-height="145"
        :color="salesCloudColors.green"
        text
        @click="createBooking"
      >
        {{ $t('newBooking') }}
      </v-btn>
      <v-btn
        v-if="useBlockSlot"
        min-width="145"
        min-height="145"
        :color="salesCloudColors.red"
        text
        @click="blockSlot"
      >
        {{ $t('blockSlot') }}
      </v-btn>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
	name: 'SlotActionDialog',
	props: {
		useBlockSlot: {
			type: Boolean,
			default: true
		}
	},
	methods: {
		createBooking() {
			this.$emit('createBooking')
		},
		blockSlot() {
			this.$emit('blockSlot')
		},
		close() {
			this.$emit('close')
		}
	},
	computed: {
		salesCloudColors() {
			return this.$store.state.salesCloudColors
		},
		createBookingWidth() {
			if (this.useBlockSlot) {
				return '145'
			}
			return '290'
		}
	}
}
</script>
